import { navigate } from 'gatsby-plugin-react-intl'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import DatacError from '../../assets/images/datac-error.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { DatacMessage } from '../../components/common'
import { useScopedIntl } from '../../hooks'
import { AccountType, setAccessTokenForSubject, verifyAccount } from '../../requests'
import { routes } from '../../routes'

interface Props {
  location: {
    search: string
    state?: {
      email?: string
    }
  }
}

const SubjectsSignUpConfirmationPage: React.FC<Props> = ({ location }) => {
  const intlSignupSuccess = useScopedIntl('auth.signup_confirmation')
  const intl = useScopedIntl('')
  const [accountVerified, setAccountVerified] = useState<boolean>(null)
  const { id, token } = queryString.parse(location.search)

  useEffect(() => {
    verifyAccount(
      { token: token as string, accountId: id as string, accountType: AccountType.Subject },
      {
        onSuccess: ({ econsentToken, accessToken, subjectId, recruitmentToken }) => {
          setAccessTokenForSubject(accessToken)
          if (econsentToken) navigate(routes.econsentSurvey(econsentToken))
          else if (subjectId) navigate(routes.subjectRepositorySurvey(subjectId))
          else if (recruitmentToken) navigate(routes.recruitmentSurvey(recruitmentToken))
          else navigate(routes.subjectDashboard)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setAccountVerified(false)
        }
      }
    )
  }, [])

  return (
    accountVerified === false && (
      <Layout accountType={AccountType.Subject}>
        <SimpleContent
          title={intlSignupSuccess('title')}
          subtitle={intlSignupSuccess('subtitle_error')}
          message={intlSignupSuccess('message_error')}
          submit=""
          Image={DatacError}
          onSubmit={() => null}
          showSubmit={false}
        />
      </Layout>
    )
  )
}

export default SubjectsSignUpConfirmationPage
